import React, { useRef, useState } from 'react';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { CommonInputProps, Labeled, useNotify } from 'react-admin';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useController } from 'react-hook-form';
// a little function to help us with reordering the result
function reorder<TItem>(
  list: TItem[],
  startIndex: number,
  endIndex: number,
): TItem[] {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const ListItem = ({
  option,
  draggableProvided,
  onDelete,
}: {
  option: string;
  draggableProvided: DraggableProvided;
  onDelete: (string) => void;
}) => {
  return (
    <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Box>
          <IconButton {...draggableProvided.dragHandleProps}>
            <DragIndicatorIcon />
          </IconButton>
          {option}
        </Box>
        <Button onClick={() => onDelete(option)}>X</Button>
      </Stack>
    </div>
  );
};

export const ReorderableList = (properties: CommonInputProps) => {
  //Custom react admin input field
  //worth reading this before you dive in here: https://marmelab.com/react-admin/doc/4.16/Inputs.html#writing-your-own-input-component
  const controlledField = useController({
    name: properties.source,
    defaultValue: [],
  });

  const notify = useNotify();

  const inputReference = useRef<HTMLInputElement>();

  const [list, _setListState] = useState<string[]>(
    () => controlledField.field.value,
  );

  //wrap setList to also push change to controlled field
  const setList = (newList: string[]) => {
    _setListState(newList);
    controlledField.field.onChange(newList);
  };

  const add = () => {
    if (!inputReference.current) {
      return;
    }

    //trim first
    inputReference.current.value = inputReference.current.value.trim();

    const toAdd = inputReference.current.value;
    if (list.includes(toAdd)) {
      notify(`Options must be unique, ${toAdd} is already an option`, {
        type: 'error',
      });
      return;
    }

    const newList = [...list];
    newList.push(toAdd);
    setList(newList);
    inputReference.current.value = '';
  };

  const remove = (toRemove: string) => {
    const newList = list.filter((option) => option !== toRemove);
    setList(newList);
  };
  return (
    <Labeled label={properties.label}>
      <Box>
        <DragDropContext
          onDragEnd={(result: DropResult) => {
            if (
              !result.destination ||
              result.destination.index === result.source.index
            ) {
              //outside of list or dropped to original position
              return;
            }

            const newList = reorder(
              list,
              result.source.index,
              result.destination.index,
            );

            setList(newList);
          }}
        >
          <Droppable
            droppableId="options"
            isCombineEnabled={false}
            key="optionsList"
          >
            {(provided) => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                spacing={0}
              >
                {list.map((option, index) => (
                  <Draggable
                    draggableId={option}
                    index={index}
                    key={'key-' + option}
                  >
                    {(draggableProvided) => (
                      <ListItem
                        option={option}
                        draggableProvided={draggableProvided}
                        onDelete={remove}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
        <Stack direction="row">
          <input type="text" ref={inputReference} />
          <Button onClick={add}>Add</Button>
        </Stack>
      </Box>
    </Labeled>
  );
};
