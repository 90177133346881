import React, { useState } from 'react';
import { Box, InputLabel, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Button,
  DateInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import {
  AdBoardLandingPage,
  AdminPreviewModal,
} from '@keyops-hcp/ui-components';

import { AdBoardStateChoices } from '../../../utils/choices';

const DetailsEditTab: React.FC = () => {
  const record = useRecordContext();

  const [preview, setPreview] = useState(false);
  const handleTogglePreview = () => setPreview(!preview);

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
        pb={2}
      >
        <Typography>
          These details populate the invite sent to participants.
        </Typography>
        <Button
          variant={'outlined'}
          label="Preview"
          onClick={handleTogglePreview}
        />
      </Box>
      {/* title */}
      <InputLabel htmlFor="title">Name</InputLabel>
      <TextInput
        variant={'outlined'}
        id="title"
        label={false}
        source="title"
        fullWidth
      />
      {/* state */}
      <InputLabel htmlFor="state">State</InputLabel>
      <SelectInput
        source="state"
        choices={AdBoardStateChoices}
        validate={required()}
      />
      {/* description */}
      <InputLabel htmlFor="description">Description</InputLabel>
      <TextInput
        variant={'outlined'}
        id="description"
        label={false}
        source="description"
        fullWidth
        multiline
      />
      {/* Objectives */}
      <InputLabel htmlFor="objectives">Objectives</InputLabel>
      <RichTextInput label={false} source="objectives" fullWidth />
      {/* Start and End Date */}
      <Box display={'flex'} width={'100%'}>
        <Box flexGrow={1} marginRight={2}>
          <InputLabel htmlFor="startDate">Start date</InputLabel>
          <DateInput
            variant={'outlined'}
            id="startDate"
            label={false}
            source="startDate"
            fullWidth
          />
        </Box>
        <Box flexBasis="50%">
          <Box display={'flex'} alignItems={'center'}>
            <InputLabel htmlFor="endDate" sx={{ mr: 1 }}>
              End date{' '}
            </InputLabel>
            <Tooltip
              placement="top"
              title={
                <Typography align="center" fontSize={'12px'}>
                  You can extend the end date of a live ad board by changing
                  this date.
                </Typography>
              }
              arrow
            >
              <InfoOutlinedIcon sx={{ fontSize: '16px' }} />
            </Tooltip>
          </Box>
          <DateInput
            variant={'outlined'}
            id="endDate"
            label={false}
            source="endDate"
            fullWidth
          />
        </Box>
      </Box>
      <SaveButton />
      <AdminPreviewModal open={preview} onClose={handleTogglePreview}>
        <AdBoardLandingPage adBoardId={String(record.id)} />
      </AdminPreviewModal>
    </>
  );
};

export default DetailsEditTab;
