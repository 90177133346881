import React from 'react';
import { AdBoardSectionProgressDto, SectionDto } from '@keyops-hcp/dtos';

import { VideoWrapper } from '../VideoWrapper';
import { AttachmentList } from '../AttachmentList';
import { SurveyWrapper } from '../SurveyWrapper';
import { DiscussionList } from '../DiscussionList';
import { DocumentMarkupActivity } from '../DocumentMarkupActivity';

export const AdBoardSectionDisplay = ({
  section,
  setIsFullScreenData,
  setCurrentSectionId,
  currentContentId,
  currentSectionProgress,
  sectionDrawerOpen,
  setSectionDrawerOpen,
}: {
  section: SectionDto;
  setIsFullScreenData: (isFullScreen: boolean) => void;
  setCurrentSectionId: (id: string, contentId?: string) => void;
  currentContentId?: string;
  currentSectionProgress?: AdBoardSectionProgressDto;
  sectionDrawerOpen?: boolean;
  setSectionDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const sectionNavigate = (contentId?: string) => {
    setCurrentSectionId(section.id, contentId);
  };

  switch (section.type) {
    case 'video': {
      return (
        <VideoWrapper
          requiredCommentCount={section.content.requiredCommentCount}
          videoContent={section?.content?.video}
          currentSectionProgress={currentSectionProgress}
        />
      );
    }
    case 'attachment': {
      return (
        <AttachmentList
          attachmentIds={section?.content.map(
            (attachmentContent) => attachmentContent.attachmentId,
          )}
          setIsFullScreenData={setIsFullScreenData}
        />
      );
    }
    case 'discussion': {
      return (
        <DiscussionList
          content={section.content}
          setIsFullScreenData={setIsFullScreenData}
          sectionNavigate={sectionNavigate}
          topicId={currentContentId}
        />
      );
    }
    case 'survey': {
      return <SurveyWrapper />;
    }
    case 'documentMarkup': {
      return (
        <DocumentMarkupActivity
          documentMarkupContent={section?.content}
          sectionDrawerOpen={sectionDrawerOpen}
          setSectionDrawerOpen={setSectionDrawerOpen}
        />
      );
    }
  }
  // may need this later
  // return (
  //   <Container>
  //     <Typography>unrecognized section type ${section.type}</Typography>
  //   </Container>
  // );
};
