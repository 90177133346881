import { RichTextInput } from 'ra-input-rich-text';
import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { ReorderableList } from '../../../components/ReorderableStringList';

export const PollEdit = (properties) => {
  return (
    <Edit {...properties}>
      <SimpleForm>
        <TextInput source="title" />
        <RichTextInput source="question" fullWidth />
        <ReorderableList source="configuration.values" label="Options" />
      </SimpleForm>
    </Edit>
  );
};
