import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Collapse, Box, useTheme } from '@mui/material';
import {
  DocumentMarkupAdBoardSectionContentDto,
  DocumentMarkupMessageDto,
  MessageDto,
} from '@keyops-hcp/dtos';
import { temporaryHighlight } from '@keyops-hcp/constants';
import {
  calculateIndex,
  highlightFromIndexes,
  handleMouseOver,
  handleMouseOut,
  handleSpanClick,
  getElementRect,
} from './markupUtils';
import { CloseButton } from '../CloseButton';
import { useOneDiscussion } from '../../hooks/useDiscussion';
import { DiscussionMessageForm } from '../DiscussionMessageForm';
import { DiscussionMessage } from '../DiscussionMessage';
import { fetchUrlHashData, scrollToMessageId } from '../../utils/discussion';
import { useAdBoardContext } from '../../context/AdBoardContext';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import { AdBoardButton } from '../AdBoardButton';
import { sanitize } from '../HTMLOutput';
import {
  useStyles,
  useMessageBoxStyles,
  useHighlightStyles,
  useHTMLDivStyles,
  useAddCommentButtonStyles,
} from './styles';
import i18next from '../../languages/i18n.config';
import 'react-quill/dist/quill.snow.css';

export const DocumentMarkupActivity = ({
  documentMarkupContent,
  sectionDrawerOpen,
  setSectionDrawerOpen,
}: {
  documentMarkupContent: DocumentMarkupAdBoardSectionContentDto;
  sectionDrawerOpen?: boolean;
  setSectionDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { currentSectionProgress, userId, role, isParticipantModerator } =
    useAdBoardContext();
  const { data: documentMarkupDiscussion } = useOneDiscussion(
    documentMarkupContent?.documentMarkup?.discussionId,
  );
  const [messages, setMessages] = useState<DocumentMarkupMessageDto[]>([]);
  const [messagePostRange, setMessagePostRange] = useState<
    { startIndex: number; endIndex: number } | undefined
  >();
  const [selectedMarkupMessageId, setSelectedMarkupMessageId] = useState<
    string | undefined
  >();
  const contentReference = useRef<HTMLDivElement | undefined>(undefined);
  const [contentSize, setContentSize] = useState({ width: 0, height: 0 });
  const [adjustedPositions, setAdjustedPositions] = useState<
    Record<string, number>
  >({});
  const [buttonPosition, setButtonPosition] = useState<
    | {
        x: number;
        y: number;
      }
    | undefined
  >();
  const { msgId } = fetchUrlHashData();

  // Initialize event listeners
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      // Ignore click if there is a selection range with actual content
      const selection = globalThis.getSelection();
      if (selection && selection.toString().length > 0) {
        return;
      }
      handleSpanClick(event, setSelectedMarkupMessageId);
    };

    // Add event listeners
    document.addEventListener('mouseover', handleMouseOver);
    document.addEventListener('mouseout', handleMouseOut);
    document.addEventListener('click', handleClick);

    // Resize observer for the html component
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setContentSize({ width, height });
      }
    });

    observer.observe(contentReference.current);

    // Cleanup listeners on unmount
    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
      document.removeEventListener('mouseout', handleMouseOut);
      document.removeEventListener('click', handleClick);
      observer.disconnect();
    };
  }, []);

  // Order + filter messages. Reset & apply highlights on html content
  useEffect(() => {
    if (!documentMarkupDiscussion?.messages) return;

    // Sort messages by startIndex & filter out message if section is not finished
    const sortedMessages = [
      ...(documentMarkupDiscussion.messages as DocumentMarkupMessageDto[]),
    ]
      .sort((a, b) => a.context.startIndex - b.context.startIndex)
      .filter((message) => {
        return (
          !(
            role === 'participant' && currentSectionProgress?.finished === false
          ) ||
          message?.userId === userId ||
          isParticipantModerator(message?.userId)
        );
      });
    if (messagePostRange) {
      // Find index where to insert messagePostRange
      const insertIndex = sortedMessages.findIndex(
        (message) => message.context.startIndex >= messagePostRange.startIndex,
      );

      // Placeholder for the inserted component
      const rangePlaceholder = {
        id: temporaryHighlight, // Unique ID for React keys
        context: {
          startIndex: messagePostRange.startIndex,
          endIndex: messagePostRange.endIndex,
        },
      } as DocumentMarkupMessageDto;

      if (insertIndex === -1) {
        sortedMessages.push(rangePlaceholder); // Append if no valid index
      } else {
        sortedMessages.splice(insertIndex, 0, rangePlaceholder); // Insert at found index
      }
    }

    if (contentReference.current) {
      // Reset the HTML to the original sanitized content
      contentReference.current.innerHTML = sanitize(
        documentMarkupContent?.documentMarkup?.content || '',
      ).__html;

      // Reapply highlights using the existing documentMarkupDiscussion data
      const ranges = sortedMessages?.map((message) => ({
        startIndex: message?.context?.startIndex,
        endIndex: message?.context?.endIndex,
        messageId: message?.id,
      }));
      highlightFromIndexes(ranges, contentReference.current);
    }

    setMessages(sortedMessages);
  }, [
    documentMarkupDiscussion,
    messagePostRange,
    documentMarkupContent,
    currentSectionProgress,
  ]);

  // Handle notification scroll
  useEffect(() => {
    if (!msgId || !documentMarkupDiscussion) return;
    const findMessageId = (messages: MessageDto[]) => {
      for (const { id, thread } of messages) {
        if (id === msgId || thread?.some((t: MessageDto) => t.id === msgId))
          return id;
      }
    };
    const foundId = findMessageId(documentMarkupDiscussion.messages);
    if (foundId) {
      setSelectedMarkupMessageId(foundId);
      scrollToMessageId(foundId);
    }
  }, [documentMarkupDiscussion, msgId, sectionDrawerOpen, location]);

  // Reset message post range when temporaryHighlight is not selected
  useEffect(() => {
    if (selectedMarkupMessageId !== temporaryHighlight) {
      setMessagePostRange(undefined);
    }
    if (selectedMarkupMessageId) setSectionDrawerOpen(false);
  }, [selectedMarkupMessageId]);

  // Calculate comment positions
  useLayoutEffect(() => {
    calculateNewPositions();
  }, [messages, selectedMarkupMessageId, contentSize]);

  // calculate box positions to anchor messages to highlights avoid overlap between messages
  const calculateNewPositions = () => {
    const newPositions: Record<string, number> = {};
    let lastBottom = 0;

    // The calculated positions are relative to the top of the wrapper
    const container = document.querySelector('#relative-content-wrapper');
    const containerRect = container?.getBoundingClientRect() || { top: 0 };
    const containerScrollTop = container?.scrollTop || 0;

    // Anchor messages to corresponding highlights
    for (const { id } of messages) {
      const boxRect = getElementRect(`[data-message-id="${id}"]`);
      const spanRect = getElementRect(`span[class*="${id}"]`);

      if (boxRect && spanRect) {
        let adjustedTop = spanRect.top - containerRect.top + containerScrollTop;

        // Add spacing for elements that overlap
        if (adjustedTop <= lastBottom) adjustedTop = lastBottom + 10;

        newPositions[id] = adjustedTop;
        lastBottom = adjustedTop + boxRect.height;
      }
    }

    // Adjust for selected message position
    if (selectedMarkupMessageId) {
      const selectedSpanRect = getElementRect(
        `span[class*="${selectedMarkupMessageId}"]`,
      );

      if (selectedSpanRect) {
        const newTop =
          selectedSpanRect.top - containerRect.top + containerScrollTop;
        const diff = newTop - newPositions[selectedMarkupMessageId];

        Object.keys(newPositions).some((key) => {
          newPositions[key] += diff;
          return key === selectedMarkupMessageId;
        });
      }
    }

    setAdjustedPositions(newPositions);
  };

  // Show the add comment button on mouse up
  const handleMouseUp = () => {
    const selection = globalThis.getSelection();
    if (selection && selection.rangeCount > 0 && selection.toString().trim()) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      const container = document.querySelector('#relative-content-wrapper');
      const containerRect = container?.getBoundingClientRect() || {
        top: 0,
        left: 0,
      };
      const containerScrollTop = container?.scrollTop || 0;
      const containerScrollLeft = container?.scrollLeft || 0;

      setButtonPosition({
        x: rect.left - containerRect.left + containerScrollLeft,
        y: rect.top - containerRect.top + containerScrollTop - 30,
      });
    } else {
      setButtonPosition(undefined);
    }
  };

  // Handle add comment button click to highlight the selected range
  const handleAddCommentClick = () => {
    const selection = globalThis.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      // Calculate start and end indexes
      const startIndex = calculateIndex(
        range.startContainer,
        range.startOffset,
        contentReference?.current,
      );
      const endIndex = calculateIndex(
        range.endContainer,
        range.endOffset,
        contentReference?.current,
      );

      // store startIndex & endIndex for message posting
      setMessagePostRange({ startIndex, endIndex });

      // Clear the selection and hide the button
      selection.removeAllRanges();
      // Drawer doesn't close if temporary-highlight is already selected & you go to add another comment
      setSectionDrawerOpen(false); // Temp fix until temporary messages are given unique id's
      setButtonPosition(undefined);
      setSelectedMarkupMessageId(temporaryHighlight);
    }
  };

  return (
    <>
      {/* Activity instructions */}
      <AdBoardSectionTaskInfo mb={2} width={contentSize?.width + 40}>
        {i18next.t('documentMarkup.infoText', {
          count: documentMarkupContent?.requiredCommentCount,
        })}
      </AdBoardSectionTaskInfo>
      <Box id="relative-content-wrapper" sx={styles.contentWrapper}>
        {/* HTML content */}
        <div
          className="ql-editor"
          ref={contentReference}
          dangerouslySetInnerHTML={sanitize(
            documentMarkupContent?.documentMarkup?.content,
          )}
          onMouseUp={handleMouseUp}
          style={useHTMLDivStyles(selectedMarkupMessageId !== undefined)}
        />

        {/* Add comment button */}
        {buttonPosition && (
          <AdBoardButton
            sx={useAddCommentButtonStyles(buttonPosition)}
            onClick={handleAddCommentClick}
          >
            {i18next.t('documentMarkup.commentButton')}
          </AdBoardButton>
        )}

        {/* Mark up messages */}
        {selectedMarkupMessageId && !sectionDrawerOpen && (
          <Collapse in orientation="horizontal" sx={styles.collapseSection}>
            <Box sx={styles.closeButtonAlign}>
              <CloseButton
                onClick={() => {
                  setSelectedMarkupMessageId(undefined);
                }}
              />
            </Box>
            <Box sx={styles.commentWrapper}>
              {messages?.map((message) => {
                return (
                  <Box
                    key={message.id}
                    data-message-id={message.id} // Use this to find the box later
                    onClick={() => setSelectedMarkupMessageId(message.id)}
                    sx={useMessageBoxStyles(
                      theme,
                      selectedMarkupMessageId === message.id,
                      adjustedPositions[message.id],
                    )}
                  >
                    {' '}
                    {message.id === temporaryHighlight ? (
                      <DiscussionMessageForm
                        discussionId={
                          documentMarkupContent?.documentMarkup?.discussionId
                        }
                        documentMarkupRange={messagePostRange}
                        setSelectedMarkupMessageId={setSelectedMarkupMessageId}
                      />
                    ) : (
                      <DiscussionMessage
                        key={message.id}
                        highlighted={msgId === message.id}
                        message={message}
                        collapsedView={selectedMarkupMessageId !== message.id}
                        // Timeout to give time for the DOM to update before calculating
                        onReplyToggle={() => {
                          setTimeout(function () {
                            calculateNewPositions();
                          }, 1);
                        }}
                        onReplyFormResize={() => {
                          setTimeout(function () {
                            calculateNewPositions();
                          }, 1);
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Collapse>
        )}
        {/* highlight, hover & selected styles */}
        <style>{useHighlightStyles(selectedMarkupMessageId)}</style>
      </Box>
    </>
  );
};
