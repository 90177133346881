// Internal Dependencies
import { Notifications } from '@mui/icons-material';
import NotificationsList from './components/List';

const resource = {
  list: NotificationsList,
  icon: Notifications,
};

export default resource;
