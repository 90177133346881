// External Dependencies
import React, { ReactNode } from 'react';
import { Box, Modal, SxProps, useTheme } from '@mui/material';
import { FiX } from 'react-icons/fi';
import { hardwareAccelerationStyles } from '../../styles';

export const BasicModal = ({
  open,
  handleClose,
  children,
  sx,
  top,
  closeEnabled = true,
}: {
  open: boolean;
  handleClose: () => void;
  children: ReactNode;
  sx?: SxProps;
  top?: number;
  closeEnabled?: boolean;
}) => {
  const theme = useTheme();
  const wrappedHandleClose = () => {
    if (closeEnabled) {
      handleClose();
    }
  };
  return (
    <Modal
      open={open}
      onClose={wrappedHandleClose}
      sx={sx}
      disableAutoFocus={true}
      slotProps={{
        backdrop: {
          sx: { backgroundColor: theme.palette.keyops.black.alpha300 },
        },
      }}
    >
      <Box
        position={'absolute'}
        top={top ? `${top}px` : '40%'}
        left={'50%'}
        bgcolor={'#ffffff'}
        boxShadow={24}
        padding={3}
        borderRadius={1}
        width={{ xs: '80%', md: '600px' }}
        minHeight={'200px'}
        sx={{
          ...hardwareAccelerationStyles,
          transform: top ? 'translate(-50%, 0)' : 'translate(-50%, -50%)',
        }}
      >
        {children}
        {closeEnabled && (
          <FiX
            onClick={handleClose}
            fontSize={25}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              right: 5,
              top: 5,
              padding: '4px 4px 0 0',
            }}
          />
        )}
      </Box>
    </Modal>
  );
};
