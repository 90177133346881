import React, { RefObject } from 'react';
import { VideoDto } from '@keyops-hcp/dtos';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AddVideoTimeEventDialog } from './AddVideoTimeEventDialog';
import ReactPlayer from 'react-player';
import { useDeleteVideoTimeEvent } from '../../../../custom-hooks/useDeleteVideoTimeEvent';
import { useNotify } from 'react-admin';

export const VideoTimeEvents = ({
  video,
  playerRef,
}: {
  video: VideoDto;
  playerRef?: RefObject<ReactPlayer | null>;
}) => {
  const notify = useNotify();
  const { mutate: deleteVideoTimeEvent } = useDeleteVideoTimeEvent(notify);
  const onDelete = async (id: string) => {
    deleteVideoTimeEvent(id);
  };

  return (
    <Box sx={{ margin: 2 }}>
      <Typography>Video Time Events</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Event</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {video.videoTimeEvents.map((videoTimeEvent) => (
              <TableRow key={'videoTimeEventRow' + videoTimeEvent.id}>
                <TableCell>{videoTimeEvent.poll.title}</TableCell>
                <TableCell>{videoTimeEvent.timestamp}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <Button onClick={() => onDelete(videoTimeEvent.id)}>X</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ textAlign: 'right' }}>
        <AddVideoTimeEventDialog video={video} playerRef={playerRef} />
      </Box>
    </Box>
  );
};
