import React, { useEffect, useState } from 'react';
import { Box, Stack, ToggleButton, Typography, useTheme } from '@mui/material';

import { PollAnswerDto, PollDto } from '@keyops-hcp/dtos';
import { BasicModal } from '../BasicModal';
import { useAnswerPoll, useGetPollAnswers } from '../../hooks';
import { useAdBoardContext } from '../../context';
import { Loader } from '../Loader';
import { HTMLOutput } from '../HTMLOutput';
import i18next from '../../languages/i18n.config';
import { analytics } from '../../utils/analytics';
import { BarChartDown } from '../../icons';
import { PrimaryButton } from '../PrimaryButton';

export const PollModal = ({
  poll,
  open,
  handleClose,
}: {
  poll: PollDto;
  open: boolean;
  handleClose: () => void;
}) => {
  if (!poll) {
    return <></>;
  }

  return (
    <BasicModal open={open} handleClose={handleClose} closeEnabled={false}>
      <Poll poll={poll} handleDone={handleClose} />
    </BasicModal>
  );
};

type PollMode = 'ask' | 'show';

export const Poll = ({
  poll,
  handleDone,
}: {
  poll: PollDto;
  handleDone: () => void;
}) => {
  const { isLoading, isError, data: answers } = useGetPollAnswers(poll.id);

  const { palette } = useTheme();

  const { userId } = useAdBoardContext();

  const [mode, setMode] = useState<PollMode>('ask');

  useEffect(() => {
    if (answers) {
      if (answers.some((answer) => answer.userId === userId)) {
        setMode('show');
      }
    } else {
      setMode('ask');
    }
  }, [answers]);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    //oops, error.  Need to move toast provider to ui components
    return <></>;
  }
  return (
    <>
      <Stack direction="row" className="textWithIcons" spacing={1}>
        <BarChartDown
          sx={{ color: palette.keyops.teal, width: '19px', height: '19px' }}
        />
        <Typography variant="body3">{i18next.t('poll.pollType')}</Typography>
      </Stack>
      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        <HTMLOutput html={poll.question} />
      </Box>
      {mode === 'ask' && <PollQuestion poll={poll} />}
      {mode === 'show' && (
        <PollAnswers poll={poll} answers={answers} handleDone={handleDone} />
      )}
    </>
  );
};

const PollAnswers = ({
  poll,
  answers,
  handleDone,
}: {
  poll: PollDto;
  answers: PollAnswerDto[];
  handleDone: () => void;
}) => {
  const { userId } = useAdBoardContext();
  const { palette } = useTheme();
  const values = poll.configuration.values;
  const valueCounts = new Array<number>(values.length);
  const userValue = answers.find((answer) => answer.userId === userId).value;
  valueCounts.fill(0);
  for (const answer of answers) {
    const index = values.indexOf(answer.value);
    if (index !== -1) {
      valueCounts[index]++;
    }
  }

  const valuePercentages = valueCounts.map((count) =>
    Math.round((count * 100) / answers.length),
  );

  analytics.track('poll_answers_viewed', {
    pollId: poll.id,
    pollTitle: poll.title,
  });
  return (
    <Box>
      <Stack spacing={3}>
        {values.map((value, index) => (
          <Stack spacing={0.25}>
            <Typography variant="body3">{value}</Typography>
            <Stack direction={'row'} spacing={2} alignItems="center">
              <Box
                sx={{
                  width: valuePercentages[index] + '%',
                  backgroundColor:
                    value === userValue
                      ? palette.keyops.teal
                      : palette.keyops.blue.lightSteelBlue20,
                  height: '8px',
                  borderRadius: '4px',
                }}
              ></Box>
              <Typography variant="body2Black">
                {valuePercentages[index]}%
              </Typography>
            </Stack>
          </Stack>
        ))}

        <PrimaryButton onClick={handleDone} type="button">
          {i18next.t('general.done')}
        </PrimaryButton>
      </Stack>
    </Box>
  );
};

const PollQuestion = ({ poll }: { poll: PollDto }) => {
  const { mutate: answerPoll, isLoading, isError } = useAnswerPoll();
  const { palette } = useTheme();
  const handleChange = (newValue: string) => {
    if (newValue) {
      setValue(newValue);
    }
  };

  const handleSubmit = () => {
    if (!value) {
      return;
    }
    answerPoll({ pollId: poll.id, value });
    analytics.track('poll_answered', {
      pollId: poll.id,
      pollTitle: poll.title,
      value,
    });
  };
  const [value, setValue] = React.useState('');

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    analytics.track('poll_viewed_error', {
      pollId: poll.id,
      pollTitle: poll.title,
    });
    //oops, error.  Need to move toast provider to ui components
    return <></>;
  }
  analytics.track('poll_viewed', { pollId: poll.id, pollTitle: poll.title });
  return (
    <>
      <Box>
        <Stack spacing={2} sx={{ marginBottom: 3 }}>
          {poll.configuration.values.map((optionValue) => (
            <ToggleButton
              onChange={() => handleChange(optionValue)}
              value={optionValue}
              selected={value === optionValue}
              key={'toggle-' + optionValue}
              disableRipple={true}
              sx={{
                justifyContent: 'left',
                color: palette.keyops.black.main,
                fontWeight: '400',
                ':hover': {
                  backgroundColor: 'transparent',
                  borderColor: palette.keyops.blue.midnightBlue,
                },
                '&.Mui-selected': {
                  color: palette.keyops.black.main,
                  backgroundColor: 'transparent',
                  borderColor: palette.keyops.blue.midnightBlue,
                },
                '&.Mui-selected:hover': {
                  color: palette.keyops.black.main,
                  backgroundColor: 'transparent',
                  borderColor: palette.keyops.blue.midnightBlue,
                },
              }}
            >
              {optionValue}
            </ToggleButton>
          ))}
        </Stack>
        <PrimaryButton onClick={handleSubmit} disabled={!value}>
          {i18next.t('poll.submitButton')}
        </PrimaryButton>
      </Box>
    </>
  );
};
