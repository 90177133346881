import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MessageBubble = (properties: SvgIconProps) => (
  <SvgIcon {...properties}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        clipRule="evenodd"
        d="m12 4c-4.41828 0-8 3.58172-8 8 0 1.6921.52425 3.2588 1.41916 4.5503.29617.4275.36757.9981.13297 1.5029l-.90484 1.9468h7.35271c4.4183 0 8-3.5817 8-8 0-4.41828-3.5817-8-8-8zm-10 8c0-5.52285 4.47715-10 10-10 5.5228 0 10 4.47715 10 10 0 5.5228-4.4772 10-10 10h-8.13841c-1.13423 0-1.85173-1.2067-1.32753-2.1984l1.08769-2.3403c-1.02554-1.5704-1.62175-3.4476-1.62175-5.4613z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </SvgIcon>
);
