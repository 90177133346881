import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Avatar, Box, Stack, Typography, Link, useTheme } from '@mui/material';

import { MessageDto } from '@keyops-hcp/dtos';
import { getMinutesAgoFromString } from '../../utils/datetime';
import { formatAuthorName } from '../../utils/common-utils';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';
import { ModeratorChip } from '../ModeratorChip';
import { Loader } from '../Loader';
import { getVideoTime } from '../../utils/datetime';
import { DiscussionChatMessages } from '../DiscussionChatMessages';
import { KeyopsSubTitle2 } from '../Headers';
import { DiscussionMessageForm } from '../DiscussionMessageForm';
import { parseAndHighlightMentions } from '../../utils/discussion';

interface DiscussionMessageProperties {
  message: MessageDto;
  highlighted?: boolean;
  collapsedView?: boolean;
  onReplyToggle?: () => void;
  onReplyFormResize?: () => void;
}

export const DiscussionMessage = ({
  message,
  highlighted,
  collapsedView,
  onReplyToggle,
  onReplyFormResize,
}: DiscussionMessageProperties): JSX.Element => {
  const theme = useTheme();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const [showMessageForm, setShowMessageForm] = useState(false);
  const { userId, isParticipantModerator, isLoading, isError } =
    useAdBoardContext();

  if (isLoading) return <Loader />;
  if (isError) return <div>{i18next.t('common.error')}</div>;

  const jumpToTime = (timestamp: number) => {
    if (timestamp !== undefined && timestamp !== null) {
      setSearchParameters({ videoTime: timestamp.toString() });
    }
  };

  const isVideo = message.type === 'video';
  const isAdmin = !!message.adminId;
  const isModerator = isParticipantModerator(message.userId);

  return (
    <Box pb="8px" width="100%" key={message.id} id={message.id}>
      {/* Author avatar */}
      <Stack
        pb="8px"
        width="100%"
        direction="row"
        spacing={1}
        alignItems="flex-start"
      >
        <Avatar
          sx={{
            backgroundColor: `${
              isAdmin ? 'keyops.blue.midnightBlue' : 'keyops.gray.400'
            }`,
            color: 'white',
            width: '40px',
            height: '40px',
            fontSize: '16px',
            fontWeight: '600',
          }}
        >
          {formatAuthorName(
            isAdmin
              ? [message?.admin?.name]
              : [message?.user?.firstName, message?.user?.lastName],
            true,
          )}
        </Avatar>
        <Box width="100%">
          <Stack pb="8px" direction="row" spacing={2} alignItems="center">
            <Typography fontWeight={500} fontSize="16px" lineHeight="24px">
              {formatAuthorName(
                isAdmin
                  ? [message?.admin?.name]
                  : [message?.user?.firstName, message?.user?.lastName],
                false,
              )}
            </Typography>
            {isModerator && <ModeratorChip />}
            {isVideo && (
              <Typography fontSize="14px">
                <Link
                  component="button"
                  sx={{ textDecoration: 'none' }}
                  onClick={() => jumpToTime(message.context.videoTimeStamp)}
                >
                  {getVideoTime(message.context.videoTimeStamp)}
                </Link>
              </Typography>
            )}
            <Typography
              fontWeight={400}
              fontSize="14px"
              lineHeight="24px"
              color="keyops.gray.600"
            >
              {getMinutesAgoFromString(message.createdAt.toString())}
            </Typography>
          </Stack>
          <Box>
            <Typography
              sx={
                highlighted && {
                  display: 'inline', // Ensures the text stays inline for proper wrapping
                  background: `linear-gradient(${theme.palette.keyops.teal}, ${theme.palette.keyops.teal})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 4px', // Width matches text, height is thickness
                  backgroundPosition: '0 100%', // Positioned at the bottom of the text
                  borderRadius: '2px', // Rounds the edges of the underline
                  boxDecorationBreak: 'clone', // Ensures underline wraps properly across lines
                  paddingBottom: '4px', // Adds spacing to accommodate the underline thickness
                }
              }
            >
              {parseAndHighlightMentions(
                message.content,
                isAdmin ? undefined : userId.toString(),
              )}
            </Typography>
          </Box>
          {/* THREADS */}
          {/* Reply button */}
          {!collapsedView && (
            <Link
              onClick={() => {
                setShowMessageForm(!showMessageForm);
                if (onReplyToggle) onReplyToggle();
              }}
              underline="none"
              style={{ cursor: 'pointer' }}
            >
              <KeyopsSubTitle2 color="primary">
                {i18next.t('ad_board.discussion.reply')}
              </KeyopsSubTitle2>
            </Link>
          )}
          {/* Reply textBox */}
          {showMessageForm && !collapsedView && (
            <Box width="100%" paddingTop="8px">
              <DiscussionMessageForm
                discussionId={message?.discussionId}
                replyTo={message}
                onPost={() => setShowMessageForm(false)}
                onFormResize={() => onReplyFormResize()}
              />
            </Box>
          )}
          {/* Display thread messages */}
          {message?.thread && message?.thread?.length > 0 && !collapsedView && (
            <DiscussionChatMessages
              isThread={true}
              pageDisplay={true}
              messages={message?.thread}
              onReplyToggle={() => onReplyToggle()}
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
};
