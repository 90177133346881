import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getAnswersByPollId } from '../api/polls';
import { PollAnswerDto } from '@keyops-hcp/dtos';

export const useGetPollAnswers = (
  pollId: string,
): UseQueryResult<PollAnswerDto[], Error> => {
  return useQuery({
    queryKey: ['getPollAnswers', pollId],
    queryFn: () => getAnswersByPollId(pollId),
    onError: (error: Error) => {
      console.error(error);
    },
  });
};
