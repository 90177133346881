import React, {
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useState,
} from 'react';
import { Box } from '@mui/material';
import ReactPlayer from 'react-player';

import { useVideo } from '../../hooks/useVideo';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';
import { analytics } from '../../utils/analytics';
import { PollDto, VideoTimeEventDto } from '@keyops-hcp/dtos';
import { PollModal } from '../Poll';

export const Video = forwardRef<
  ReactPlayer,
  {
    videoId: string;
    videoTime?: number;
  }
>(({ videoId, videoTime }, playerReference) => {
  const { video, loading, error } = useVideo(videoId);

  const playerContainerReference = useRef(null);

  const { updateProgress = () => {} } = useAdBoardContext();

  const [pollModelOpen, setPollModalOpen] = useState(false);
  const [poll, setPoll] = useState<PollDto>();

  //Returns the current react player from the ref
  const getCurrentPlayer = () => {
    if (
      playerReference &&
      'current' in playerReference &&
      playerReference.current
    ) {
      return playerReference.current;
    }
  };

  //Returns the internal player from the current react player ref
  const getCurrentInternalPlayer = () => {
    return getCurrentPlayer()?.getInternalPlayer();
  };

  const jumpToVideoTime = () => {
    const player = getCurrentPlayer();
    if (player) {
      const duration = player.getDuration();
      const jumpTo = duration - videoTime < 5 ? 0 : videoTime;
      if (jumpTo) {
        player.seekTo(videoTime, 'seconds');
      }
    }
  };

  const handleCuePoint = async (cuepoint: { data: VideoTimeEventDto }) => {
    const player = getCurrentInternalPlayer();
    if (player) {
      await player.pause();
      setPoll(cuepoint.data.poll);
      setPollModalOpen(true);
    }
  };

  const addVideoTimeEvents = async () => {
    if (video.videoTimeEvents.length === 0) {
      //no events, can exit early
      return;
    }
    const player = getCurrentInternalPlayer();
    if (player && typeof player.addCuePoint === 'function') {
      try {
        for (const videoTimeEvent of video.videoTimeEvents) {
          player.addCuePoint(videoTimeEvent.timestamp, videoTimeEvent);
        }
        player.on('cuepoint', handleCuePoint);
      } catch (error) {
        console.error('Error adding cue point:', error);
      }
    } else {
      console.warn('addCuePoint method is not supported by the player');
    }
  };

  const onPlayerReady = () => {
    jumpToVideoTime();
    addVideoTimeEvents();
  };

  useEffect(jumpToVideoTime, [videoTime, playerReference]);

  const onReady = useCallback(onPlayerReady, [
    videoTime,
    playerReference,
    video,
  ]);

  const updateVideoProgress = () => {
    if (
      playerReference &&
      'current' in playerReference &&
      playerReference.current
    ) {
      const player = playerReference.current as ReactPlayer;
      if (updateProgress) {
        const currentTime = player.getCurrentTime();
        analytics.track(`AdBoard Progress Video`, { currentTime });
        updateProgress({ currentTime: player.getCurrentTime() });
      }
    }
  };

  const onPause = useCallback(updateVideoProgress, [
    playerReference,
    updateProgress,
  ]);

  const onEnded = useCallback(updateVideoProgress, [
    playerReference,
    updateProgress,
  ]);

  const handlePollModalClose = async () => {
    setPollModalOpen(false);
    const player = getCurrentInternalPlayer();
    if (player) {
      await player.play();
    }
  };

  if (loading) return <div>{i18next.t('common.loading')}</div>;
  if (error) return <div>{i18next.t('common.error')}</div>;

  return (
    <Box>
      <Box
        ref={playerContainerReference}
        display={'block'}
        margin={'auto'}
        mt={2}
      >
        <ReactPlayer
          url={video?.link}
          ref={playerReference}
          controls
          onStart={() => console.log('starting video')}
          onPause={onPause}
          onReady={onReady}
          onEnded={onEnded}
          width={'98%'}
          height={'450px'}
        />
      </Box>
      <PollModal
        open={pollModelOpen}
        poll={poll}
        handleClose={handlePollModalClose}
      />
    </Box>
  );
});

Video.displayName = 'Video';
