import axios from 'axios';
import { DocumentMarkupAdBoardSectionDto } from '@keyops-hcp/dtos';

import { getAPIUrl } from '../utils/env';

const apiUrl = getAPIUrl();

// updateDocumentMarkupSection
// takes content (html string) & requiredCommentCount
export const updateOneDocumentMarkupSection = async (
  id: string,
  fieldsToUpdate: { content: string; requiredCommentCount: number },
): Promise<DocumentMarkupAdBoardSectionDto> => {
  try {
    const response = await axios.patch(
      `${apiUrl}/document-markup/${id}`,
      fieldsToUpdate,
      {
        headers: {
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );
    return response.data.data;
  } catch (error) {
    console.error(
      `Error update one document markup section with id: ${id} and fieldsToUpdate: ${fieldsToUpdate}`,
      error,
    );
    throw error;
  }
};
