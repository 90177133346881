/* eslint-disable unicorn/prevent-abbreviations */
//need above because there are some names required by mui that this rule doesn't like, e.g. TypographyPropsVariantOverrides
// External Dependencies
import { createTheme } from '@mui/material/styles';

// Update Typography's prop types to include `body3`
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body2Black: true;
    body3: true;
    h4: false;
  }
}
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // Keep existing
    sm: true;
    md: true;
    lg: true;
    xl: true;
    customMd: true; // Additional custom breakpoint
  }

  interface Palette {
    keyops: {
      background: string;
      blue: {
        keyopsBlue: string;
        keyopsBlue50: string;
        arcticBlue: string;
        arcticBlue20: string;
        arcticBlue30: string;
        lightSteelBlue: string;
        lightSteelBlue20: string;
        midnightBlue: string;
        midnightBlue50: string;
        midnightBlue90: string;
      };
      teal: string;
      black: {
        main: string;
        alpha600: string;
        alpha300: string;
      };
      white: {
        main: string;
        alpha800: string;
        alpha900: string;
      };
      gray: {
        main: string;
        50: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
      };
    };
  }
  interface PaletteOptions {
    keyops?: {
      background?: string;
      blue?: {
        keyopsBlue?: string;
        keyopsBlue50?: string;
        arcticBlue?: string;
        arcticBlue20?: string;
        arcticBlue30?: string;
        lightSteelBlue?: string;
        lightSteelBlue20?: string;
        midnightBlue?: string;
        midnightBlue50: string;
        midnightBlue90?: string;
      };
      teal?: string;
      black?: {
        main?: string;
        alpha600?: string;
        alpha300?: string;
      };
      white?: {
        main?: string;
        alpha800?: string;
        alpha900?: string;
      };
      gray?: {
        main?: string;
        50?: string;
        100?: string;
        200?: string;
        300?: string;
        400?: string;
        500?: string;
        600?: string;
        700?: string;
      };
    };
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
    body2Black: React.CSSProperties;
  }
  // allow configuration using `createTheme()`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body2Black?: React.CSSProperties;
  }
  // Update the Typography's variant prop options
  interface TypographyPropsVariantOverrides {
    body3: true;
    body2Black: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      ...createTheme().breakpoints.values, // Include default breakpoints
      customMd: 1028, // Add your custom breakpoint
    },
  },
  typography: {
    fontFamily: 'Poppins',
    button: {
      textTransform: 'none',
    },
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    subtitle2: undefined,
    body3: {},
    body2Black: {},
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'outlined' },
              style: {
                color: '#3376D1',
              },
            },
          ],
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {},
      },
    },
  },
  palette: {
    secondary: {
      main: '#39BDAF',
    },
    background: {
      default: '#39BDAF',
    },
    keyops: {
      background: '#F3F9FC',
      blue: {
        keyopsBlue: '#3376D1',
        keyopsBlue50: '#3376D180', //this isn't 90%
        arcticBlue: '#E0F0FF',
        arcticBlue20: '#E0F0FF33',
        arcticBlue30: '#E0F0FF4D',
        lightSteelBlue: '#7C8DB5',
        lightSteelBlue20: '#7C8DB533',
        midnightBlue: '#1A4B7B',
        midnightBlue90: '#1A4B7BE5',
        midnightBlue50: '#1A4B7B80',
      },
      teal: '#39BDAF',
      black: { main: '#1A242B', alpha600: '#0000007A', alpha300: '#00000029' },
      white: { main: '#FFFFFF', alpha800: '#ffffffcc', alpha900: '#FFFFFFEB' },
      gray: {
        main: '#B3B3B3',
        50: '#F7FAFC',
        100: '#EDF2F7',
        200: '#E2E8F0',
        300: '#CBD5E0',
        400: '#A0AEC0',
        500: '#718096',
        600: '#4A5568',
        700: '#2D3748',
      },
    },
  },
});

theme.typography.h1 = {
  color: theme.palette.keyops.black.main,
  fontSize: '1.375rem', // 22px
  lineHeight: 1.45, // 32px
  fontWeight: 500,
  [theme.breakpoints.down('md')]: {
    fontSize: '1.125rem', // 18px
    lineHeight: 1.333, // 24px
  },
};
theme.typography.h2 = {
  color: theme.palette.keyops.black.main,
  fontSize: '1rem', // 16px
  fontWeight: 500,
  lineHeight: 1.5, // 24px
};
//same as h2 for now, not sure if that should be the case
theme.typography.h3 = {
  color: theme.palette.keyops.black.main,
  fontSize: '0.75rem', // 12px
  fontWeight: 400,
  textTransform: 'uppercase',
  lineHeight: 1.33, // 16px
};
theme.typography.subtitle1 = {
  color: theme.palette.keyops.blue.lightSteelBlue,
  fontSize: '0.875rem', // 14px
  fontWeight: 400,
  lineHeight: 1.143, // 16px
};
theme.typography.body1 = {
  fontSize: '0.875rem', // 14px
  lineHeight: 1.71, // 24px
};
theme.typography.body2 = {
  color: theme.palette.keyops.blue.lightSteelBlue,
  fontSize: '1rem', // 16px
  lineHeight: 1.5, // 24px
  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem', // 14px
    lineHeight: 1.143, // 16px
  },
};
theme.typography.body2Black = {
  color: theme.palette.keyops.black.main,
  fontSize: '1rem', // 16px
  lineHeight: 1.5, // 24px
  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem', // 14px
    lineHeight: 1.143, // 16px
  },
};
theme.typography.body3 = {
  fontSize: '0.875rem', // 14px
  lineHeight: 1.57, // 22px
};

export { theme };
