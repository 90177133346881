import { PollAnswerDto } from '@keyops-hcp/dtos';
import { axiosCall } from '../utils/api';
import { POLLS_ENDPOINT } from '../utils/api-routes';

export const answerPoll = async (
  pollId: string,
  value: string,
): Promise<PollAnswerDto> => {
  try {
    const results = await axiosCall(
      false,
      'post',
      `${POLLS_ENDPOINT}/${pollId}/answers`,
      undefined,
      undefined,
      { value },
    );
    if (results.status === 200) {
      return results.data.data || [];
    }
  } catch (error) {
    console.error('Error creating one answer poll', error);
    throw error;
  }
};

export const getAnswersByPollId = async (
  pollId: string,
): Promise<PollAnswerDto[]> => {
  try {
    const results = await axiosCall(
      false,
      'get',
      `${POLLS_ENDPOINT}/${pollId}/answers`,
      undefined,
      undefined,
      {},
    );
    if (results.status === 200) {
      return results.data.data || [];
    }
  } catch (error) {
    console.error('Error getting poll answers', error);
    throw error;
  }
};
