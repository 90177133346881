import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { RefObject, useState } from 'react';
import { Loading, useGetList, useNotify } from 'react-admin';
import ReactPlayer from 'react-player';

import { VideoDto } from '@keyops-hcp/dtos';
import { useCreateVideoTimeEvent } from '../../../../custom-hooks/useCreateVideoTimeEvent';

export const AddVideoTimeEventDialog = ({
  playerRef,
  video,
  onSave = () => {},
}: {
  playerRef?: RefObject<ReactPlayer | null>;
  video: VideoDto;
  onSave?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const [timestampState, setTimestampState] = useState(0);
  const [selectedPollId, setSelectedPollId] = useState<string>();
  const { data: polls, isLoading, error } = useGetList('Poll');
  const notify = useNotify();
  const { mutate: createVideoTimeEvent } = useCreateVideoTimeEvent(notify);

  const handleClickOpen = async () => {
    setOpen(true);

    if (playerRef && 'current' in playerRef && playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
      const currentTime = await player.getCurrentTime();
      setTimestampState(Number.parseInt(currentTime));
      player.pause();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const shouldWarnVideoTimeEventsAreClose = (timestamp: number) => {
    return video.videoTimeEvents.some(
      (event) => Math.abs(event.timestamp - timestamp) < 10,
    );
  };

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseInt(event.target.value);
    setTimestampState(newValue);
  };

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Typography>Failed to retrieve polls</Typography>;
  }

  polls.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen} size="medium">
        Add Poll
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            createVideoTimeEvent({
              pollId: selectedPollId,
              videoId: video.id,
              timestamp: timestampState,
            });
            onSave();
            handleClose();
          },
        }}
      >
        <DialogTitle>Add Poll</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          {shouldWarnVideoTimeEventsAreClose(timestampState) && (
            <Typography>
              Warning: there is already a poll within 10s of this timestamp.
            </Typography>
          )}
          <TextField
            required
            id="timestamp"
            name="timestamp"
            label="Timestamp"
            type="number"
            value={timestampState}
            onChange={updateValue}
            fullWidth
            variant="outlined"
          />

          <FormControl fullWidth>
            <InputLabel id="pollLabel">Poll</InputLabel>
            <Select
              id="poll"
              labelId="pollLabel"
              label="Poll"
              onChange={(event) =>
                setSelectedPollId(event.target.value as string)
              }
            >
              {polls.map((poll) => (
                <MenuItem value={poll.id} key={'pollMenuItem' + poll.id}>
                  {poll.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            Add Poll
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
