import { queryClient, useMutation } from '@keyops-hcp/ui-components';
import { deleteVideoTimeEvent } from '../api/videos';

export const useDeleteVideoTimeEvent = (notify) => {
  return useMutation<unknown, unknown, string>(
    async (id) => {
      const updatedSection = await deleteVideoTimeEvent(id);
      return updatedSection;
    },
    {
      // Options for the mutation
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['getAdBoardById'],
        });
        // Refetch all sections
        await queryClient.invalidateQueries({
          queryKey: ['getAdBoardSections'],
        });
        notify('Removed poll from video', { type: 'success' });
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to remove the poll from the video', { type: 'error' });
      },
    },
  );
};
