import DOMPurify from 'dompurify';
import React from 'react';
import { HTMLOutputProps as HTMLOutputProperties } from './HTMLOutput.types';

export const sanitize = (htmlContent: string) => ({
  __html: DOMPurify.sanitize(htmlContent, {
    ALLOWED_ATTR: ['class'], // Ensure classes are allowed
  }),
});

/**
 * Sanitizes using DOMPurify html and outputs wrapped in a div.
 * @param props with html to be sanitized
 * @returns
 */
export const HTMLOutput: React.FC<HTMLOutputProperties> = ({ html }) => {
  return <div dangerouslySetInnerHTML={sanitize(html)} />;
};
