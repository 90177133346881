import { VideoTimeEventCreationDto } from '@keyops-hcp/dtos';
import { queryClient, useMutation } from '@keyops-hcp/ui-components';
import { createVideoTimeEvent } from 'src/api/videos';

export const useCreateVideoTimeEvent = (notify) => {
  return useMutation<unknown, unknown, VideoTimeEventCreationDto>(
    async (videoTimeEvent: VideoTimeEventCreationDto) => {
      const updatedSection = await createVideoTimeEvent(videoTimeEvent);
      return updatedSection;
    },
    {
      // Options for the mutation
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['getAdBoardById'],
        });
        // Refetch all sections
        await queryClient.invalidateQueries({
          queryKey: ['getAdBoardSections'],
        });
        notify('Poll added to video', { type: 'success' });
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to add poll to video', { type: 'error' });
      },
    },
  );
};
