// External Dependencies
import Ballot from '@mui/icons-material/Ballot';

// Internal Dependencies
import { PollsList } from './components/List';
import { PollEdit } from './components/Edit';
import { PollCreate } from './components/Create';

const resource = {
  list: PollsList,
  edit: PollEdit,
  create: PollCreate,
  icon: Ballot,
};

export default resource;
