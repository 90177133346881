import React from 'react';
import { Menu } from 'react-admin';

export const CustomMenu = () => (
  <Menu>
    <Menu.DashboardItem />
    <Menu.ResourceItem name="Admins" />
    <Menu.ResourceItem name="Company" />
    <Menu.ResourceItem name="Users" />
    <Menu.ResourceItem name="Engagement" />
    <Menu.ResourceItem name="Invitation" />
    <Menu.ResourceItem name="Referrals" />
    <Menu.ResourceItem name="Payment" />
    <Menu.ResourceItem name="RewardsTransaction" />
    <Menu.ResourceItem name="RewardsPeriod" />
    <Menu.ResourceItem name="RewardsTier" />
    <Menu.ResourceItem name="EngagementFile" />
    <Menu.ResourceItem name="FeatureSwitches" />
    <Menu.ResourceItem name="OnboardingLink" />
    <Menu.ResourceItem name="PhysicianOnboarding" />
    <Menu.ResourceItem name="Notification" />
    <Menu.ResourceItem name="AdBoards" />
    <Menu.ResourceItem name="Poll" />
  </Menu>
);
