import React, { RefObject, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  useTheme,
} from '@mui/material';

import { usePostDiscussionMessage } from '../../hooks';

import { MentionInput } from '../MentionInput';
import { extractMentionIds } from '../../utils/discussion';
import { MessageDto } from '@keyops-hcp/dtos';

interface DiscussionMessageFormProperties {
  discussionId: string;
  documentMarkupRange?: { startIndex: number; endIndex: number };
  playerRef?: RefObject<ReactPlayer | null>;
  replyTo?: MessageDto;
  onPost?: () => void; // Optional onPost logic (Ex: closing reply messageForm, scrolling to bottom, etc...)
  onFormResize?: () => void; // Optional onFormResize logic (Ex: you want to change styling when the form size changes)
  setSelectedMarkupMessageId?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}
export const DiscussionMessageForm = ({
  discussionId,
  documentMarkupRange,
  playerRef,
  replyTo,
  onPost,
  onFormResize,
  setSelectedMarkupMessageId,
}: DiscussionMessageFormProperties): React.JSX.Element => {
  const { palette } = useTheme();
  const initialMessage: string = undefined;
  const formReference = useRef<HTMLFormElement | null>(null);

  // Form handling
  const {
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm<{ message: string }>({
    defaultValues: {
      message: initialMessage,
    },
  });

  // Post one message (update the progress and invalidate the get all discussions in the mutation itself)
  const {
    mutate: postDiscussionMessage,
    isLoading,
    isError,
  } = usePostDiscussionMessage();

  const handlePostMessage: SubmitHandler<{ message: string }> = async (
    messageToSave,
  ) => {
    const mentionedUserIds = extractMentionIds(messageToSave.message);

    const threadId = replyTo?.threadId ?? replyTo?.id;

    if (documentMarkupRange) {
      postDiscussionMessage({
        message: messageToSave.message,
        discussionId,
        type: 'documentMarkup',
        threadId,
        context: {
          startIndex: documentMarkupRange?.startIndex,
          endIndex: documentMarkupRange?.endIndex,
          mentions: mentionedUserIds,
        },
        setSelectedMarkupMessageId,
      });
    } else if (playerRef) {
      const player = playerRef.current.getInternalPlayer();
      const currentTime = await player.getCurrentTime();

      postDiscussionMessage({
        message: messageToSave.message,
        discussionId,
        type: 'video',
        threadId,
        context: {
          videoTimeStamp: Number.parseInt(`${currentTime}`, 10),
          mentions: mentionedUserIds,
        },
      });
    } else {
      postDiscussionMessage({
        message: messageToSave.message,
        discussionId,
        threadId,
        type: 'discussion',
        context: {
          mentions: mentionedUserIds,
        },
      });
    }
    reset();

    if (onPost) onPost();
  };

  const handleOnFocusEvent = () => {
    if (playerRef && 'current' in playerRef && playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
      player.pause();
    }
  };

  useEffect(() => {
    if (!formReference.current) return;
    const observer = new ResizeObserver(() => {
      if (onFormResize) onFormResize();
    });
    observer.observe(formReference.current);
    return () => observer.disconnect();
  }, []);

  if (isError) return <>Something went wrong</>;

  const userToTag = replyTo?.threadId ? replyTo.user : undefined;

  return (
    <Box width={'100%'} alignSelf={'center'}>
      <form
        ref={formReference}
        onSubmit={handleSubmit(handlePostMessage)}
        onFocus={handleOnFocusEvent}
        style={{
          transition: 'opacity 0.5s ease-in-out', // Smooth form transitions when submitting
          opacity: isLoading ? 0.5 : 1, // Dim the form when loading
        }}
      >
        <FormGroup>
          <FormControl variant="outlined">
            <div
              style={{
                backgroundColor: palette.keyops.white.main,
                borderRadius: '4px',
              }}
            >
              <MentionInput
                setValue={setValue}
                control={control}
                userToTag={userToTag}
              />
              {errors.message && (
                <FormHelperText>{errors.message.message}</FormHelperText>
              )}
            </div>
          </FormControl>
        </FormGroup>
      </form>
    </Box>
  );
};
