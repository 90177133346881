import React, { useState } from 'react';
import { Loading, useNotify, Button } from 'react-admin';
import { Box, Typography, Container } from '@mui/material';
import { DocumentMarkupAdBoardSectionDto, SectionDto } from '@keyops-hcp/dtos';
import { RichTextEditor } from './RichTextEditor';
import { useUpdateDocumentMarkupSection } from 'src/custom-hooks/useUpdateDocumentMarkupSection';

interface DocumentEditSectionProps {
  documentMarkupSection: DocumentMarkupAdBoardSectionDto;
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>;
}

export const DocumentMarkupEditSection: React.FC<DocumentEditSectionProps> = ({
  documentMarkupSection,
  setEditSection,
}) => {
  // Success and error notifications
  const notify = useNotify();
  // Loading handling
  const [processing, setProcessing] = useState<boolean>(false);

  // Update the document and the documentsSection
  const { mutate: updateDocumentMarkupSection } =
    useUpdateDocumentMarkupSection(
      documentMarkupSection,
      setEditSection,
      notify,
      setProcessing,
    );

  const [content, setContent] = useState(
    documentMarkupSection.content?.documentMarkup?.content || '',
  );
  const [requiredCommentCount, setRequiredCommentCount] = useState(
    documentMarkupSection.content?.requiredCommentCount || 0,
  );

  // Loading handling
  if (processing) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Document Markup Content</Typography>
      <RichTextEditor value={content} onChange={setContent} />
      <Box>
        Required comment count:{' '}
        <input
          value={requiredCommentCount}
          onChange={(event) => {
            const value = parseInt(event.target.value);
            setRequiredCommentCount(isNaN(value) ? 0 : value);
          }}
        />
      </Box>
      <Box display={'flex'} flexDirection={'row-reverse'} mt={2}>
        <Button
          label="Save"
          variant={'contained'}
          sx={{ textTransform: 'initial' }}
          onClick={() =>
            updateDocumentMarkupSection({ content, requiredCommentCount })
          }
        />
      </Box>
    </Box>
  );
};
