import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Button, Loading, useEditContext, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import {
  AdBoardDto,
  AdBoardSectionGroupDto,
  SectionDto,
} from '@keyops-hcp/dtos';
import {
  AdminPreviewModal,
  AdminPreviewContent,
  useAdBoardById,
} from '@keyops-hcp/ui-components';

import AddSection from './sections/AddSection';
import EditSection from './sections/EditSection';
import {
  createOneAdBoardSection,
  updateOneAdBoardSection,
} from '../../../api/adboard-sections-api';
import SectionGroupsList from './sections/SectionGroupsList';
import { AddEditSectionGroup } from './sections/AddEditSectionGroup';
import EmptyContentCard from './sections/EmptyContentCard';

const ContentEditTab: React.FC = () => {
  const notify = useNotify();
  const [preview, setPreview] = useState(false);
  const handleTogglePreview = () => setPreview(!preview);

  const navigate = useNavigate();
  const { record, isFetching } = useEditContext<AdBoardDto>();

  const [showAddSectionView, setShowAddSectionView] = useState(false);
  const [addingToSectionGroup, setAddingToSectionGroup] =
    useState<AdBoardSectionGroupDto>();
  const [editSection, setEditSection] = useState<SectionDto | null>(null);

  if (!record) {
    navigate('/AdBoards');
    return <></>;
  }

  const {
    data: adBoard,
    isLoading,
    isError,
    refetch,
  } = useAdBoardById(record.id);

  useEffect(() => {
    if (!editSection) {
      return;
    }
    //if edit section is active, we need to reset it cause the adboard state just changed.
    const updatedSection = adBoard.sections.find(
      (section) => section.id === editSection.id,
    );
    if (!updatedSection) {
      return;
    }
    setEditSection(updatedSection);
  }, [adBoard]);

  const handleAddSection = (sectionGroup: AdBoardSectionGroupDto) => {
    setAddingToSectionGroup(sectionGroup);
    setShowAddSectionView(true);
  };
  const handleCloseAddSection = (section?: SectionDto) => {
    setShowAddSectionView(false);
    if (section) setEditSection(section);
  };
  const saveSection = async (data: Partial<SectionDto>) => {
    try {
      if (!isFetching) {
        const sectionData: Partial<SectionDto> = {
          title: data.title ?? 'Untitled activity',
          description: data.description,
          index: addingToSectionGroup?.sections?.length ?? 0,
          type: data.type,
          adBoardId: record.id,
          sectionGroupId: addingToSectionGroup?.id,
        };
        const createdSection = await createOneAdBoardSection(sectionData);
        await refetch();
        handleCloseAddSection(createdSection);
      }
    } catch (error) {
      console.error(error);
      notify('Failed to create a section', { type: 'error' });
    }
  };

  const handleEditSection = (section: SectionDto) => setEditSection(section);
  const handleCloseEditSection = () => setEditSection(null);
  const updateSectionDetails = async (data: Partial<SectionDto>) => {
    if (editSection) {
      await updateOneAdBoardSection({ id: editSection.id, ...data });
      await refetch();
    }
  };

  if (isLoading) return <Loading />;
  if (isError) navigate('/AdBoards');

  if (!adBoard) {
    navigate('/AdBoards');
    return <></>;
  }

  return (
    <>
      {showAddSectionView && (
        <AddSection
          onSaveSection={saveSection}
          onCloseAddSection={handleCloseAddSection}
        />
      )}
      {editSection && (
        <EditSection
          editSection={editSection}
          setEditSection={setEditSection}
          onCloseEditSection={handleCloseEditSection}
          onUpdateSectionDetails={updateSectionDetails}
        />
      )}
      {!showAddSectionView && !editSection && (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            pb={2}
          >
            <Button
              variant="outlined"
              label="Preview"
              onClick={handleTogglePreview}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            pb={2}
          >
            <Box>
              <Typography fontWeight={600}>Contents</Typography>
              <Typography>
                Your ad board content is organized into sections that contain
                the activities you want participants to complete. These sections
                and activities form the table of contents displayed to
                participants.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#4A5568',
                }}
              >
                <Typography>{adBoard.sectionGroups.length} sections</Typography>
                <Divider orientation="vertical" sx={{ mx: 1 }} flexItem />
                <Typography>
                  {adBoard?.sectionGroups.reduce(
                    (sum, sectionGroup) =>
                      sum + (sectionGroup.sections?.length ?? 0),
                    0,
                  )}{' '}
                  activities
                </Typography>
              </Box>
            </Box>
            <AddEditSectionGroup afterClose={handleAddSection} />
          </Box>
          {adBoard.sectionGroups.length > 0 ? (
            <SectionGroupsList
              onEditSection={handleEditSection}
              onAddSection={handleAddSection}
              sectionGroups={adBoard.sectionGroups}
            />
          ) : (
            <EmptyContentCard />
          )}
        </>
      )}
      <AdminPreviewModal open={preview} onClose={handleTogglePreview}>
        <AdminPreviewContent />
      </AdminPreviewModal>
    </>
  );
};

export default ContentEditTab;
