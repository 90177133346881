import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

export const PollCreate = (properties) => {
  return (
    <Create {...properties}>
      <SimpleForm>
        <TextInput source="title" />
        <RichTextInput source="question" fullWidth />
      </SimpleForm>
    </Create>
  );
};
