import React from 'react';
import { Box, Collapse, useMediaQuery, useTheme } from '@mui/material';

import { SectionNavigationContent } from '../SectionNavigationContent';

interface SectionsDrawerProperties {
  setCurrentSectionId: (id: string) => void;
  sectionDrawerOpen: boolean;
  toggleSectionDrawer?: () => void;
}

export const SectionsDrawer = ({
  // To be able to navigate in admin preview
  setCurrentSectionId,
  sectionDrawerOpen = false,
  toggleSectionDrawer,
}: SectionsDrawerProperties): JSX.Element => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Box
      display={isMobile ? 'none' : 'flex'}
      flexDirection="column"
      alignItems="flex-start"
      height={'100%'}
    >
      <Collapse
        in={sectionDrawerOpen}
        timeout={300}
        orientation="horizontal"
        sx={{
          height: '100%',
          border: '1px solid var(--gray-200, #E2E8F0)',
          borderTop: 0,
        }}
      >
        <Box
          width={420}
          bgcolor="background.paper"
          overflow="auto"
          sx={{
            transform: sectionDrawerOpen ? 'scale(1)' : 'scale(0)',
          }}
        >
          <SectionNavigationContent
            handleSectionDrawerToggle={toggleSectionDrawer}
            setCurrentSectionId={setCurrentSectionId}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
