import React, { useRef, useState } from 'react';
import { Button, Loading } from 'react-admin';
import ReactPlayer from 'react-player';
import { Box } from '@mui/material';

import { VideoDto } from '@keyops-hcp/dtos';
import { UseMutateFunction } from '@keyops-hcp/ui-components';
import { VideoTimeEvents } from './VideoTimeEvents';

interface VideoFormProperties {
  video?: VideoDto | null;
  title?: string;
  setTitle: (value: string) => void;
  description?: string;
  setDescription: (value: string) => void;
  requiredCommentCount?: number;
  setRequiredCommentCount: (value: number) => void;
  updateVideoSection: UseMutateFunction<
    VideoDto,
    unknown,
    {
      title?: string;
    },
    unknown
  >;
}

export const VideoForm: React.FC<VideoFormProperties> = ({
  video,
  requiredCommentCount,
  setRequiredCommentCount,
  updateVideoSection,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const playerReference = useRef<ReactPlayer | null>(null);
  return (
    <>
      {isLoading ? (
        <>
          <Loading loadingSecondary="It could take several minutes for the video to process" />
        </>
      ) : (
        <ReactPlayer
          ref={playerReference}
          url={video?.link}
          onReady={() => {
            setIsLoading(false);
          }}
          onError={() => {
            setIsLoading(true);
          }}
          controls
        />
      )}
      <VideoTimeEvents video={video} playerRef={playerReference} />
      <Box>
        Required comment count:{' '}
        <input
          value={requiredCommentCount}
          onChange={(event) => {
            const value = Number.parseInt(event.target.value);
            setRequiredCommentCount(Number.isNaN(value) ? 0 : value);
          }}
        />
      </Box>
      <Box display={'flex'} flexDirection={'row-reverse'} mt={2}>
        <Button
          label="Save"
          variant={'contained'}
          sx={{ textTransform: 'initial' }}
          onClick={() => updateVideoSection({ title: 'Video for adboard' })}
        />
      </Box>
    </>
  );
};
