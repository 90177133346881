import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';

export const PollsList = () => {
  return (
    <>
      <List>
        <Datagrid rowClick="edit">
          <TextField source="title" />
          <TextField source="question" />
        </Datagrid>
      </List>
    </>
  );
};
