import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { createHttpLink } from '@apollo/client/link/http';
import { setContext } from '@apollo/client/link/context';
// import { clearCredentials } from "./lib";

const apiUri =
  process.env.REACT_APP_GRAPHQL_URL ?? 'http://localhost:9000/graphql';

const httpLink = createHttpLink({
  uri: apiUri,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const operationNameLink = new ApolloLink((operation, forward) => {
  const operationName = operation.operationName || 'unknown';
  operation.setContext(({ headers = {} }) => ({
    headers,
    uri: `${apiUri}?${operationName}`,
  }));
  return forward(operation);
});

//from https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-465305186
//we need to strip `__typename` properties in results from queries because they'll get rejected when
//sent back to server.  This is fixed in version 3.8.0, but we're before thatgit stat
function stripProperty(object, propertyToDelete) {
  for (const property in object) {
    if (
      typeof object[property] === 'object' &&
      !(object[property] instanceof File)
    ) {
      delete object.property;
      const newData = stripProperty(object[property], propertyToDelete);
      object[property] = newData;
    } else {
      if (property === propertyToDelete) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete object[property];
      }
    }
  }
  return object;
}

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = stripProperty(operation.variables, '__typename');
  }
  return forward ? forward(operation) : undefined;
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    operationNameLink,
    removeTypenameMiddleware,
    httpLink,
  ]),
  cache: new InMemoryCache({}),
});

export default apolloClient;
