import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

export const RichTextEditor = ({ value, onChange }) => {
  const [content, setContent] = useState(value || '');

  // Handle file upload
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/html') {
      try {
        const text = await file.text(); // Use Blob#text() to read file content
        setContent(text); // Set the editor's content to the uploaded HTML
      } catch (error) {
        console.error('Failed to read file:', error);
        alert('There was an error reading the file.');
      }
    } else {
      alert('Please upload a valid HTML file.');
    }
  };

  const handleChange = (content) => {
    setContent(content);
    if (onChange) onChange(content);
  };

  const modules = {
    toolbar: [
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      ['clean'],
    ],
  };

  return (
    <div>
      {/* Upload HTML File */}
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="upload-html" style={{ marginRight: '10px' }}>
          Upload HTML File:
        </label>
        <input
          type="file"
          id="upload-html"
          accept=".html"
          onChange={handleFileUpload}
        />
      </div>
      <ReactQuill
        theme="snow"
        value={content}
        onChange={handleChange}
        modules={modules}
        placeholder="Write something amazing..."
      />
    </div>
  );
};
