import {
  AdBoardProgressDto,
  AdBoardSectionGroupDto,
  SectionDto,
} from '@keyops-hcp/dtos';

/**
 * @param {string} sectionId - The current section id
 * @param {SectionDto[]} sections - The ad board sections
 * @param {AdBoardProgressDto | undefined}  adBoardProgress - The progress in the ad board (optional as the admin does not have this notion)
 * @returns {SectionDto} - The found section
 */
export const getCurrentSection = (
  sectionId: string,
  sections: SectionDto[],
  adBoardProgress?: AdBoardProgressDto,
): SectionDto => {
  if (!sections) return undefined;

  //if it's "current" we go to the latest that the user has unlocked
  if (
    (!sectionId || sectionId === 'current') &&
    sections.length > 0 &&
    adBoardProgress
  ) {
    const lastAccessibleSectionId = adBoardProgress.latestSection;

    return (
      sections.find((section) => section.id === lastAccessibleSectionId) ??
      sections[0]
    );
  }

  //otherwise, we try to use it as a section id
  //if we still can't find it, return the first
  return sections.find((section) => section.id === sectionId) ?? sections[0];
};

/**
 * Returns the nature of the next task in the adboard
 *  - 'activity' if next section is in the current sectionsGroup
 *  - 'section' if next section is in the next sectionsGroup
 *  - 'finished' if no other sectionsGroup and sections
 *
 * @param {AdBoardSectionGroupDto[]} sectionsGroups
 * @param {string} currentSectionId
 * @returns {'activity' | 'section' | 'finished'}
 */
export const getNextSectionType = (
  sectionsGroups: AdBoardSectionGroupDto[],
  currentSectionId: string,
):
  | 'attachment'
  | 'discussion'
  | 'survey'
  | 'video'
  | 'documentMarkup'
  | 'section'
  | 'finished' => {
  if (!currentSectionId) throw new Error(`currentSectionId is undefined`);
  // Loop through sectionsGroups
  for (let index = 0; index < sectionsGroups.length; index++) {
    const sectionsGroup = sectionsGroups[index];

    // Check if the current section exists in this sectionsGroup
    const currentSectionIndex = sectionsGroup?.sections?.findIndex(
      (section) => section.id === currentSectionId,
    );

    // If section is found in the current sectionsGroup
    if (currentSectionIndex !== undefined && currentSectionIndex !== -1) {
      const nextSectionIndex = currentSectionIndex + 1;

      // Check if there's another section in the same group
      if (
        sectionsGroup.sections &&
        nextSectionIndex < sectionsGroup.sections.length
      ) {
        return sectionsGroup?.sections[nextSectionIndex].type;
      }

      // Check if there's a next group with sections
      if (
        index + 1 < sectionsGroups.length &&
        sectionsGroups[index + 1].sections?.length
      ) {
        return 'section';
      }

      // No next section
      return 'finished';
    }
  }

  throw new Error(`currentSectionId not found in sectionsGroups data`);
};

/**
 *
 * @param {AdBoardSectionGroupDto} sectionsGroup - the sectionsGroup in which to count the finished sections
 * @param {AdBoardProgressDto} adBoardProgress - the progress for the related ad board
 * @returns {number} - The number of finished sections in the given sectionGroup
 */
export const countFinishedSectionsInSectionGroup = (
  sectionGroup: AdBoardSectionGroupDto,
  adBoardProgress: AdBoardProgressDto,
): number => {
  const sectionsIds = new Set(
    sectionGroup.sections.map((section) => section.id),
  );
  const currentProgressSections =
    adBoardProgress.progressDetails.sectionProgress.filter(
      (sectionProgress) => {
        if (sectionsIds.has(sectionProgress.sectionId))
          return sectionProgress.sectionId;
      },
    );

  const countFinishedSections = currentProgressSections.reduce(
    (count, currentProgressSection) => {
      return currentProgressSection.finished ? count + 1 : count;
    },
    0,
  );

  return countFinishedSections;
};
