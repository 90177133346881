import React, { useCallback } from 'react';
import { Box, Card, CardHeader, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { SectionDto } from '@keyops-hcp/dtos';

interface SectionCardProperties {
  section: SectionDto;
  onEditSection: (data: SectionDto) => void;
  onDeleteSection: (data: SectionDto) => void;
  openSectionDeleteDialog: (section: SectionDto) => void;
  dragHandleProps;
}

const SectionCard: React.FC<SectionCardProperties> = ({
  section,
  onEditSection,
  onDeleteSection,
  openSectionDeleteDialog,
  dragHandleProps,
}) => {
  const getCard = (title: string, subheader?: string) => {
    return (
      <CardHeader
        avatar={
          <IconButton {...dragHandleProps}>
            <DragIndicatorIcon />
          </IconButton>
        }
        title={`${title}`}
        titleTypographyProps={{ variant: 'body1' }}
        subheader={subheader}
        subheaderTypographyProps={{
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '24px',
        }}
        action={
          <>
            <IconButton
              color="primary"
              onClick={() => onEditSection(section)}
              title="Edit activity"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => openSectionDeleteDialog(section)}
              title="Delete activity"
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </>
        }
      />
    );
  };
  // Display sections depending on their type
  const getMainContent = useCallback(
    (section: SectionDto) => {
      switch (section.type) {
        case 'attachment':
          return getCard(
            `Documents: ${section.title}`,
            `${section.content.length} documents`,
          );
        case 'discussion':
          return getCard(
            `Discussion board: ${section.title}`,
            `${section.content.length} ${
              section.content.length === 1 ? 'topic' : 'topics'
            }`,
          );
        case 'video':
          return getCard(`Video: ${section.title}`, section.description);
        case 'survey':
          return getCard(`Survey: ${section.title}`, section.description);
        case 'documentMarkup':
          return getCard(
            `
              Document mark up: ${section.title}
            `,
            section.description,
          );
        default:
          return null;
      }
    },
    [onEditSection, onDeleteSection],
  );

  return (
    <Box key={section.id}>
      <Card variant="outlined">{getMainContent(section)}</Card>
    </Box>
  );
};

export default SectionCard;
