import { useMutation } from '@keyops-hcp/ui-components';
import { NotificationType } from 'react-admin';
import { SectionDto, DocumentMarkupAdBoardSectionDto } from '@keyops-hcp/dtos';
import { useUpdateSectionContent } from './useUpdateSectionContent';
import { updateOneDocumentMarkupSection } from '../api/documentMarkup';

export const useUpdateDocumentMarkupSection = (
  section: DocumentMarkupAdBoardSectionDto,
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>,
  notify: (
    message: string | React.ReactNode,
    options?: NotificationOptions & {
      type?: NotificationType;
    },
  ) => void,
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { mutate: updateSectionContent } =
    useUpdateSectionContent(setEditSection);

  return useMutation<
    DocumentMarkupAdBoardSectionDto,
    unknown,
    { content: string; requiredCommentCount: number }
  >(
    async ({
      content,
      requiredCommentCount,
    }): Promise<DocumentMarkupAdBoardSectionDto> => {
      // First update the Document
      setProcessing(true);

      const updatedDocumentMarkupSection = await updateOneDocumentMarkupSection(
        section.content.id,
        {
          content,
          requiredCommentCount,
        },
      );

      return updatedDocumentMarkupSection;
    },
    {
      onSuccess: (updatedDocumentMarkupSection) => {
        if (updatedDocumentMarkupSection) {
          try {
            // Then update the section (and re-set the section currently under edition)
            updateSectionContent({
              sectionId: section.id,
              content: updatedDocumentMarkupSection.id,
            });
            notify('Document markup updated successfully', { type: 'success' });
          } catch (error) {
            console.error('Failed to update section:', error);
          } finally {
            setProcessing(false);
          }
        }
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to update the document markup', { type: 'error' });
      },
    },
  );
};
