import { queryClient, useMutation } from '@keyops-hcp/ui-components';
import { deleteOneAdBoardAttachmentSection } from '../api/adboard-sections-api';

export const useDeleteAttachmentSection = (setEditSection, notify) => {
  return useMutation<
    unknown,
    unknown,
    { sectionId: string; attachmentId: string }
  >(
    async ({ sectionId, attachmentId }) => {
      const updatedSection = await deleteOneAdBoardAttachmentSection(
        sectionId,
        attachmentId,
      );
      return updatedSection;
    },
    {
      // Options for the mutation
      onSuccess: (updatedSection) => {
        if (updatedSection) {
          // Refetch all sections
          queryClient.invalidateQueries({
            queryKey: ['getAdBoardSections'],
          });

          setEditSection(updatedSection); // Update the state with the new section
          notify('Section successfully deleted', { type: 'success' });
        }
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to delete the attachment', { type: 'error' });
      },
    },
  );
};
