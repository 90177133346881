import React from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@mui/system';

import { BaseElementProps as BaseElementProperties } from '../types';

export const PrimaryButton = ({
  children,
  ...properties
}: BaseElementProperties) => {
  const { palette } = useTheme();

  return (
    <Button
      variant="contained"
      sx={{
        width: properties.width ?? '100%',
        minWidth: properties.minWidth ?? '100%',
        height: properties.height ?? '40px',
        px: properties.px ?? 4,
        borderRadius: '6px',
        backgroundColor: palette.keyops.blue.keyopsBlue,
        '&:hover': {
          backgroundColor: palette.keyops.blue.midnightBlue,
        },
        '&:disabled': {
          color: palette.keyops.blue.lightSteelBlue,
        },
      }}
      disableRipple
      disableElevation
      {...properties}
    >
      {children}
    </Button>
  );
};
