import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { Stack } from '@mui/material';
import {
  AdBoardSectionProgressDto,
  AdBoardVideoSectionProgressCombined,
  VideoDto,
} from '@keyops-hcp/dtos';

import { Video } from '../Video';
import { VideoComments } from '../VideoComments';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import i18next from '../../languages/i18n.config';
import { VideoDiscussion } from '../VideoDiscussion';

export const VideoWrapper = ({
  videoContent,
  requiredCommentCount,
  currentSectionProgress,
}: {
  videoContent: VideoDto;
  requiredCommentCount: number;
  currentSectionProgress?: AdBoardSectionProgressDto;
}) => {
  const playerReference = useRef<ReactPlayer | null>(null);

  // Ensure type safety for `currentSectionProgress`
  const videoTime =
    currentSectionProgress && 'currentTime' in currentSectionProgress
      ? (currentSectionProgress as AdBoardVideoSectionProgressCombined)
          .currentTime
      : 0;

  // rm VideoComments when no need anymore to check if there is a discussionId in videoContent
  return (
    <Stack spacing={2}>
      <AdBoardSectionTaskInfo>
        {i18next.t('video.infoText', { count: requiredCommentCount })}
      </AdBoardSectionTaskInfo>
      <Video
        videoId={videoContent?.id}
        videoTime={videoTime}
        ref={playerReference}
      />
      {videoContent?.discussionId ? (
        <VideoDiscussion
          discussionId={videoContent?.discussionId}
          playerRef={playerReference}
          requiredCount={requiredCommentCount}
        />
      ) : (
        <VideoComments
          videoId={videoContent?.id}
          ref={playerReference}
          requiredCount={requiredCommentCount}
        />
      )}
    </Stack>
  );
};
