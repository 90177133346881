// External Dependencies
import Groups from '@mui/icons-material/Groups';

// Internal Dependencies
import AdBoardEdit from './components/Edit';
import AdBoardList from './components/List';

const resource = {
  list: AdBoardList,
  edit: AdBoardEdit,
  icon: Groups,
};

export default resource;
