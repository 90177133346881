import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const User = (properties: SvgIconProps) => (
  <SvgIcon {...properties}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <g clipRule="evenodd" fill="currentColor" fillRule="evenodd">
        <path d="m12 4c-1.6569 0-3 1.34315-3 3s1.3431 3 3 3 3-1.34315 3-3-1.3431-3-3-3zm-5 3c0-2.76142 2.23858-5 5-5 2.7614 0 5 2.23858 5 5s-2.2386 5-5 5c-2.76142 0-5-2.23858-5-5z" />
        <path d="m12 20c-2.92885 0-4.66537-.8861-5.66461-2.0292-.30447-.3483-.38382-.6545-.37462-.9057.00981-.268.12567-.5781.38646-.8959.53567-.6528 1.5541-1.1692 2.65278-1.1692h5.99999c1.0987 0 2.1171.5164 2.6528 1.1692.2608.3178.3766.6279.3864.8959.0093.2512-.0701.5574-.3746.9057-.9992 1.1431-2.7357 2.0292-5.6646 2.0292zm-7.17041-.7129c1.47256 1.6845 3.82369 2.7129 7.17041 2.7129 3.3467 0 5.6979-1.0284 7.1704-2.7129.6043-.6913.8972-1.4835.8675-2.2952-.0291-.7948-.3634-1.5119-.839-2.0915-.9371-1.1419-2.5361-1.9004-4.1989-1.9004h-5.99999c-1.66275 0-3.26177.7585-4.19885 1.9004-.4756.5796-.80994 1.2967-.83905 2.0915-.02973.8117.26326 1.6039.86748 2.2952z" />
      </g>
    </svg>
  </SvgIcon>
);
