import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../wrappers';
import { PollAnswerDto } from '@keyops-hcp/dtos';
import { answerPoll } from '../api/polls';

type AnswerPollParameters = {
  pollId: string;
  value: string;
};

export const useAnswerPoll = () => {
  return useMutation<PollAnswerDto, unknown, AnswerPollParameters>(
    async ({ pollId, value }): Promise<PollAnswerDto> => {
      const createdAnswer = await answerPoll(pollId, value);
      return createdAnswer;
    },
    {
      onSuccess: (createdAnswer) => {
        queryClient.invalidateQueries({
          queryKey: ['getPollAnswers', createdAnswer.pollId],
        });
      },
      onError: (error) => {
        console.error(error);
      },
    },
  );
};
